/* Global Styles */
:root {
 --primary-color: #77d215;
 --admin-color: #b0c4de;
 --dark-color: #212226;
 --light-color: #f4f4f4;
 --danger-color: #dc3545;
 --success-color: #3c1361;
}

* {
 box-sizing: border-box;
 margin: 0;
 padding: 0;
}
.close {
 position: absolute;
 right: 200px;
 top: 225px;
 width: 32px;
 height: 32px;
 opacity: 0.3;
}
.close:hover {
 opacity: 1;
}
.close:before,
.close:after {
 position: absolute;
 left: 15px;
 content: " ";
 height: 33px;
 width: 2px;
 background-color: #333;
}
.close:before {
 transform: rotate(45deg);
}
.close:after {
 transform: rotate(-45deg);
}

body {
 font-family: "Arial", sans-serif;
 font-size: 1rem;
 line-height: 1.6;
 background-color: #fff;
 color: #333;
}

a {
 color: var(--primary-color);
 text-decoration: none;
}

a:hover {
 color: #666;
 cursor: pointer;
}

img {
 width: 100%;
}

/* Utilities */
.container {
 max-width: 1500px;
 margin: auto;
 overflow: hidden;
 padding: 0 2rem;
}

/* Text Styles*/
.x-large {
 font-size: 4rem;
 line-height: 1.2;
 margin-bottom: 1rem;
}

.x-largedinger {
 height: 700px;
}

.large {
 font-size: 3rem;
 line-height: 1.2;
 margin-bottom: 1rem;
}

.small {
 font-size: 0.5rem;
 line-height: 1.2;
 margin-bottom: 1rem;
}

.x-small {
 font-size: 0.2rem;
 line-height: 1.2;
 margin-bottom: 1rem;
}

.lead {
 font-size: 1.5rem;
 margin-bottom: 1rem;
}

.text-center {
 text-align: center;
}

.text-primary {
 color: var(--primary-color);
}

.text-dark {
 color: var(--dark-color);
}

.text-success {
 color: var(--success-color);
}

.text-danger {
 color: var(--danger-color);
}

.text-center {
 text-align: center;
}

.text-right {
 text-align: right;
}

.from-date {
 font-size: 12px;
 color: #333;
 opacity: 0.7;
}

.hero-image {
 background-size: cover;
 background-position: center;
 height: 100vh;
 display: flex;
 align-items: center;
 justify-content: center;
 position: relative;
}

.overlay {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background-color: rgba(0, 0, 0, 0.6);
 display: flex;
 align-items: center;
 justify-content: center;
}

.overlay-text {
 color: white;
 font-size: 2rem;
 text-align: center;
}

.text-left {
 text-align: left;
}

/* Center All */
.all-center {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin: auto;
 justify-content: center;
 align-items: center;
 text-align: center;
}

.all-center2 {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin: auto;
}

/* Cards */
.card {
 padding: 1rem;
 border: #ccc 1px dotted;
 margin: 0.7rem 0;
}

/* List */
.list {
 margin: 0.5rem 0;
}

.list li {
 padding-bottom: 0.3rem;
}

/* Padding */
.p {
 padding: 0.5rem;
}
.p-1 {
 padding: 1rem;
}
.p-2 {
 padding: 2rem;
}
.p-3 {
 padding: 3rem;
}
.py {
 padding: 0.5rem 0;
}
.py-1 {
 padding: 1rem 0;
}
.py-2 {
 padding: 2rem 0;
}
.py-3 {
 padding: 3rem 0;
}
.px-2 {
 padding: 0 2rem;
}

/* Margin */
.m {
 margin: 0.5rem;
}
.m-1 {
 margin: 1rem;
}
.m-2 {
 margin: 2rem;
}
.m-3 {
 margin: 3rem;
}
.my {
 margin: 0.5rem 0;
}
.my-1 {
 margin: 1rem 0;
}
.my-2 {
 margin: 2rem 0;
}
.my-3 {
 margin: 3rem 0;
}
.mx-3 {
 margin: 0 5rem;
}
.mx-2 {
 margin: 0 2rem;
}
.Select-option:selected {
 background-color: red !important;
}
.map {
 overflow: hidden;
}
.grid-hero {
 display: grid;
 grid-template-columns: repeat(6, 1fr);
 position: absolute;
 grid-template-rows: repeat(4, 16vh);
 grid-template-areas:
  "overlay"
  "copy";
}

.grid-zero {
 display: grid;
 grid-template-columns: repeat(4, 1fr);
 grid-template-rows: repeat(8, 95px);
 background-color: #ffaf7a;
 grid-template-areas:
  "overlay"
  "copy"
  "form";
}

.grid-recent {
 display: grid;
 grid-template-columns: 15px 200px;
 grid-gap: 0rem;
}
.employeegrid {
 display: grid;
 grid-template-columns: 1fr;
 grid-template-rows: 1fr;
 grid-template-areas: "grid" "text";
}

.text {
 grid-area: text;
 grid-row-start: 1;
 grid-column-start: 1;
}
.image {
 grid-area: grid;
 grid-row-start: 1;
 grid-column-start: 1;
}

.grid-ship {
 display: grid;
 grid-template-columns: 2fr 3fr 1fr;
}

.grid-popkis {
 display: grid;
 grid-template-columns: 1fr 4fr 1fr;
}

.grid-contact {
 display: grid;
 grid-template-columns: 2fr 1fr;
}

.grid-deal {
 display: grid;
 grid-template-columns: 1fr 5fr 1fr;
}

.grid-strategy {
 display: grid;
 grid-template-columns: 2fr 1fr;
 grid-template-rows: repeat(2 1fr);
 grid-template-areas:
  "copy"
  "overlay";
}

.strategy-text {
 grid-area: copy;
 display: grid;
 grid-row-start: 1;
 grid-column-start: 2;
}

.strategy-img {
 grid-area: overlay;
 display: grid;
 grid-column-start: 1;
 grid-column-end: 2;
 grid-row-start: 1;
 grid-row-end: 2;
}

.grid-home {
 display: grid;
 grid-gap: 3rem;
 grid-template-columns: repeat(6, 1fr);
 grid-template-rows: repeat(3, 1fr);
 grid-template-areas:
  "bodyimg"
  "bodyimg2"
  "bodyimg3";
}

.navgrid h3 {
 margin-top: 2rem;
 margin-left: 2rem;
}
.navgrid ul {
 margin-top: 2rem;
 margin-right: 1rem;
}
.overlay {
 grid-area: overlay;
 display: grid;
 position: relative;
 top: 0;
 height: 77vh;

 grid-row-start: 1;

 grid-column-start: 1;

 z-index: -999999;
}

.consimg {
 grid-area: overlay;
 display: grid;
 grid-row-start: 1;
 grid-row-end: 3;
 grid-column-start: 4;
 grid-column-end: 7;
}
.solsimg {
 grid-area: overlay;
 display: grid;
 grid-row-start: 1;
 grid-row-end: 2;
 grid-column-start: 4;
 grid-column-end: 7;
}

.contactimg {
 grid-area: overlay;
 display: grid;
 grid-row-start: 2;
 grid-row-end: 3;
 grid-column-start: 3;
 grid-column-end: 6;
}

.bodyimg p {
 grid-row-start: 1;
 grid-row-end: 2;
 grid-column-start: 2;
 grid-column-end: 5;
 position: absolute;
 width: 63rem;
 height: 30rem;
 z-index: 1;
 opacity: 33%;
}

.bodyimg h1 {
 grid-row-start: 1;
 grid-row-end: 2;
 grid-column-start: 2;
 grid-column-end: 5;
 position: absolute;
 width: 10rem;
 height: 5rem;
 font-size: 1.5rem;
 z-index: 9999;
}

.video-port {
 grid-row-start: 1;
 grid-row-end: 3;
 grid-column-start: 4;
 grid-column-end: 5;
 position: absolute;
 left: 35rem;
 width: 35rem;
 height: 25rem;
 z-index: 10000;
}

.bodyimg2 h1 {
 grid-row-start: 1;
 grid-row-end: 2;
 grid-column-start: 2;
 grid-column-end: 5;
 position: absolute;
 width: 10rem;
 height: 5rem;
 font-size: 1.5rem;
 z-index: 9999;
}

.bodyimg3 h1 {
 grid-row-start: 1;
 grid-row-end: 2;
 grid-column-start: 2;
 grid-column-end: 5;
 position: absolute;
 width: 10rem;
 height: 5rem;
 font-size: 1.5rem;
 z-index: 9999;
}

.bodyimg2 p {
 grid-row-start: 2;
 grid-row-end: 3;
 grid-column-start: 2;
 grid-column-end: 5;
 position: absolute;
 width: 63rem;
 height: 30rem;
 z-index: 1;
 opacity: 33%;
}

.bodyimg3 p {
 grid-row-start: 2;
 grid-row-end: 3;
 grid-column-start: 2;
 grid-column-end: 5;
 position: absolute;
 width: 63rem;
 height: 30rem;
 z-index: 1;
 opacity: 33%;
}
.bodyimg {
 grid-area: "bodyimg";
 display: grid;
 z-index: 0;
 grid-row-start: 1;
 grid-row-end: 2;
 grid-column-start: 1;
 grid-column-end: 7;
}

.bodyimg2 {
 grid-area: "bodyimg2";
 display: grid;
 z-index: 0;
 grid-row-start: 2;
 grid-row-end: 3;
 grid-column-start: 1;
 grid-column-end: 7;
}

.bodyimg3 {
 grid-area: "bodyimg3";
 display: grid;
 z-index: 0;
 grid-row-start: 3;
 grid-row-end: 4;
 grid-column-start: 1;
 grid-column-end: 7;
}

.bodyimg img {
 width: 74rem;
 height: 30rem;
}

.bodyimg2 img {
 width: 74rem;
 height: 30rem;
}

.bodyimg3 img {
 width: 74rem;
 height: 30rem;
}

.contactimg img {
 width: 5rem;
 height: 20rem;
 z-index: 0;
}

.homecopy {
 grid-area: copy;
 display: grid;
 grid-row-start: 2;
 grid-row-end: 8;
 grid-column-start: 1;
 grid-column-end: 5;
 z-index: 99999;
}

.navgrid {
 z-index: 9999;
 display: grid;
 grid-template-columns: 1fr 3fr;
}

.conscopy {
 grid-area: copy;
 display: grid;
 grid-row-start: 1;
 grid-column-start: 2;
 grid-column-end: 4;
 z-index: 2;
}

.solscopy {
 grid-area: copy;
 display: grid;
 grid-row-start: 1;
 grid-column-start: 1;
 grid-column-end: 4;
 z-index: 2;
 left: 20;
}

.feedbackgrid {
 display: grid;
 grid-template-columns: 1fr 3fr;
}

.formfiller {
 height: 12pt;
 width: 22pt;
 border: 1pt solid;
 background-color: #dcfffa;
}

.contactcopy {
 grid-area: copy;
 display: grid;
 grid-row-start: 1;
 grid-row-end: 4;
 grid-column-start: 2;
 grid-column-end: 4;
 z-index: 2;
 border-radius: 0px 50px 0px 50px;
}

.nav {
 grid-area: "nav";
 display: flex;
}

.reg {
 grid-area: "reg";
 width: 200px;
}

.max {
 grid-area: "max";
 width: 400px;
}

.sub {
 grid-area: sub;
 width: 50px;
}

.block {
 grid-area: block;
 display: grid;
 grid-template-columns: 1;
 grid-template-rows: repeat(4 1fr);
}

/* Grid */
.grid-2 {
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 grid-gap: 1rem;
}

.grid-2a {
 display: grid;
 grid-template-columns: 1fr 2fr;
 grid-gap: 1rem;
}

.grid-2b {
 display: grid;
 grid-template-columns: 1fr 6fr;
 grid-gap: 1rem;
}

.grid-2d {
 display: grid;
 grid-template-columns: 6fr 1fr;
}

.profile-container {
 display: grid;
 grid-template-columns: 1fr;
 grid-template-rows: auto 1fr;
 gap: 20px;
 height: 100vh;
}

.oic-container {
 padding-left: 166px;
}

.oic-topdiv {
 padding-left: 174px;
 margin-bottom: 20px;
}

.oic-bottomdiv {
 width: 333px;
 height: 333px;
}

.cnc-topdiv {
 padding-left: 174px;
 margin-bottom: 20px;
}

.chart-container {
 grid-row: 1 / 2;
}

.profile-info-container {
 grid-row: 2 / 3;
}

.profile-info {
 display: flex;
 flex-direction: column;
}

.section {
 margin-bottom: 20px;
}

.item {
 display: flex;
 align-items: center;
 margin-bottom: 5px;
}

label {
 font-weight: bold;
 margin-right: 10px;
}

span {
 margin-left: 5px;
}

.chat-container {
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 padding: 10px;
}

.chat-bubble {
 display: inline-block;
 background-color: #e5e5ea;
 border-radius: 20px;
 padding: 10px 15px;
 margin-bottom: 10px;
}

.chat-messages {
 display: flex;
 overflow-y: scroll;
 flex-direction: column;
}

.sender {
 color: white;
 align-self: flex-end;
 background-color: #007aff;
}

.receiver {
 color: black;
 align-self: flex-start;
 background-color: #e5e5ea;
}
.grid-2c {
 display: grid;
 grid-template-columns: 2fr 1fr;
 grid-gap: 0.25rem;
}

.grid-3 {
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-gap: 1rem;
}

.gridspan {
 grid-column-start: 1 span 2;
}

.grid-4 {
 display: grid;
 grid-template-columns: repeat(4, 1fr);
 grid-gap: 1rem;
}

.grid-5 {
 display: grid;
 grid-template-columns: repeat(5, 1fr);
 grid-gap: 1rem;
}

@property --num {
 syntax: "<integer>";
 initial-value: 0;
 inherits: false;
}

.btn {
 display: inline-block;
 background: var(--light-color);
 color: #333;
 padding: 0.4rem 1.3rem;
 font-size: 1rem;
 border: none;
 cursor: pointer;
 margin-right: 0.5rem;
 transition: opacity 0.2s ease-in;
 outline: none;
}

.btn-link {
 background: none;
 padding: 0;
 margin: 0;
}

.btn-block {
 display: block;
 width: 100%;
}

.btn-sm {
 font-size: 0.8rem;
 padding: 0.3rem 1rem;
 margin-right: 0.2rem;
}

.badge {
 display: inline-block;
 font-size: 0.8rem;
 padding: 0.2rem 0.7rem;
 text-align: center;
 margin: 0.3rem;
 background: var(--light-color);
 color: #333;
 border-radius: 5px;
}

.alert {
 padding: 0.7rem;
 margin: 1rem 0;
 opacity: 0.9;
 background: var(--light-color);
 color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
 background: var(--primary-color);
 color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
 background: var(--light-color);
 color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
 background: var(--dark-color);
 color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
 background: var(--danger-color);
 color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
 background: var(--success-color);
 color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
 background: #fff;
 color: #333;
 border: #ccc solid 1px;
}

.offercard:hover {
 background: #fffe71;
 color: #333;
}
.btn:hover {
 opacity: 0.8;
}
.grid-lien {
 display: grid;
 grid-template-columns: 25vw 50vw;
}
.bg-light,
.badge-light {
 border: #ccc solid 1px;
}

.round-img {
 border-radius: 50%;
}

/* Forms */

.form-text {
 display: block;
 margin-top: 0.3rem;
 color: #888;
}

table th,
table td {
 padding: 1rem;
 text-align: left;
}

table th {
 background: var(--light-color);
}

/* Navbar */
.navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 0.7rem 2rem;
 z-index: 1;
 width: 100%;
 opacity: 0.9;
 margin-bottom: 1rem;
}

.navbar ul {
 display: flex;
}

.navbar a {
 color: #fff;
 padding: 0.45rem;
 margin: 0 0.25rem;
}

.navbar a:hover {
 color: var(--light-color);
}

.navbar .welcome span {
 margin-right: 0.6rem;
}

/* Mobile Styles */

canvas {
 width: 100vw;
 background: var(--primary-color);
 min-width: 100%;
 box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 9px 0px;
 height: 700px;
 max-height: 700px;

 text-align: center;
}

.chart-container {
 position: relative;
}

@property --num {
 syntax: "<integer>";
 initial-value: 0;
 inherits: false;
}

@keyframes counter {
 from {
  --num: 0;
 }
 to {
  --num: 99999;
 }
}

.flip-digit {
 display: inline-block;
 margin: 0; /* Remove any default margin */
 padding: 0; /* Remove any default padding */
 font-family: monospace;
 animation-name: flip;
 animation-duration: 5s;
 animation-fill-mode: both;
 perspective: 1000px;
 animation-timing-function: ease-in-out;
}
.currency-display {
 margin-left: -4px; /* Remove any default margin */
 padding: 0; /* Remove any default padding */
 display: inline;
 white-space: nowrap;
}
@keyframes roll-down {
 0%,
 10% {
  transform: translateY(0);
 }
 25%,
 75% {
  transform: translateY(-20%);
 }
 90%,
 100% {
  transform: translateY(-100%);
 }
}

@keyframes roll-up {
 0%,
 10% {
  transform: translateY(0);
 }
 25%,
 75% {
  transform: translateY(20%);
 }
 90%,
 100% {
  transform: translateY(100%);
 }
}
.summary-container {
 font-family: Arial, sans-serif;
 margin: 0 auto;
 width: 80%;
 padding: 20px;
 box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
 border-radius: 5px;
}

.summary-section {
 margin-bottom: 30px;
}

.summary-section h2 {
 color: #333;
 font-size: 20px;
 margin-bottom: 10px;
}

.summary-section p {
 color: #666;
 font-size: 12px;
}
@keyframes bounce {
 0%,
 20%,
 50%,
 80%,
 100% {
  transform: translateY(0);
 }
 40% {
  transform: translateY(-30%);
 }
 60% {
  transform: translateY(-15%);
 }
}

.roll-down {
 display: inline-block;
 animation-duration: 1.5s;
 animation-fill-mode: both;
 animation-name: roll-down;
 animation-timing-function: steps(10); /* Increase the number of steps */
}

.roll-up {
 display: inline-block;
 animation-duration: 1.5s;
 animation-fill-mode: both;
 animation-name: roll-up;
 animation-timing-function: steps(10); /* Increase the number of steps */
}
.currency-label {
 display: inline-block;
 margin-right: 5px; /* Adjust the margin as needed */
}

.currency-value {
 display: inline-block;
 margin-left: -10px; /* Adjust the negative margin as needed */
}
@keyframes flip {
 0% {
  transform-style: preserve-3d;
  transform: rotateX(0);
 }
 2% {
  transform-style: preserve-3d;
  transform: rotateX(-180deg);
 }
 98% {
  transform-style: preserve-3d;
  transform: rotateX(-180deg);
 }
 100% {
  transform-style: preserve-3d;
  transform: rotateX(0);
 }
}

.tooltip-content {
 margin-top: 0; /* Remove top margin to align with h2 */
}
.custom-tooltip {
 background: var(--primary-color);

 padding: 2rem;
 position: absolute;
 z-index: 1; /* Ensures the tooltip appears above the chart */
 pointer-events: none; /* Allows interaction with the chart */
 color: white;
 padding: 5px;
 border-radius: 3px;
 font-size: 12px;
}

.messages {
 width: 400px;
 margin: 0 auto;
}

.message {
 margin: 0.5em;
}

.message.tx {
 text-align: right;
}

.message .bubble {
 position: relative;
 display: inline-block;
 vertical-align: top;
 background: #0d47a1;
 color: #fff;
 padding: 0.5em 1em;
 border-radius: 0.5em;
}

.message.rx .bubble {
 background: white;
 color: #212121;
}

.message .icon {
 display: inline-block;
 width: 2em;
 height: 2em;
 margin-right: 0.5em;
 border-radius: 1em;
 background: white;
}

.bubble.l-caret::before,
.bubble.r-caret::before {
 position: absolute;
 display: block;
 content: "";
 top: 0;
 width: 0;
 height: 0;
 border-left: 0.5em solid transparent;
 border-right: 0.5em solid transparent;
 border-top: 0.5em solid #0d47a1;
}

.message.rx .bubble.l-caret::before,
.message.rx .bubble.r-caret::before {
 border-top: 0.5em solid white;
}

.bubble.l-caret::before {
 left: -0.5em;
}

.bubble.r-caret::before {
 right: -0.5em;
}

@media (max-width: 700px) {
 .oic-container,
 .oic-topdiv,
 .oic-bottomdiv,
 .cnc-topdiv,
 .six-yearcontainer {
  padding-left: 0;
  margin-bottom: 0;
 }
 .hide-sm {
  display: none;
 }
 .grid-2,
 .grid-3,
 .grid-4 {
  grid-template-columns: 1fr;
 }
 .dingleberries {
  display: none;
 }
 .grid-zero {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffaf7a;
 }
 .homecopy {
  margin-bottom: 10px;
  background-color: #ffaf7a;
 }
 /* Text Styles */
 .x-large {
  font-size: 3rem;
 }

 .large {
  font-size: 2rem;
 }

 .lead {
  font-size: 1rem;
 }

 /* Navbar */
 .navbar {
  display: block;
  text-align: center;
 }

 .navbar ul {
  text-align: center;
  justify-content: center;
 }
}
